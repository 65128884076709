import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Layout from "src/components/layout";
import sideMenuStore from "src/components/side-menu/side-menu-store";
import Query from "../../components/utils/utils";
import {
  GeneratePage,
  getDescription,
  getPageHeader,
  getTitle,
} from "../../components/utils/utils";

const searchString = "how-the-game-is-played";

const GuideLines = () => {
  const setIsSideMenuOpen = sideMenuStore((state) => state.setIsSideMenuOpen);
  const [width, setWidth] = useState<number>(0);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = false;

  const [apiData, setApiData] = useState(Query);
  return (
    <>
      <Helmet>
        <title>{getTitle(apiData, searchString)}</title>
        <meta
          name='description'
          content={getDescription(apiData, searchString)}
        />
      </Helmet>
      <Layout
        html={GeneratePage(apiData, searchString, isMobile)}
        header={getPageHeader(
          apiData,
          searchString,
          isMobile
        ).pageTitleLine1?.toUpperCase()}
        header2={getPageHeader(
          apiData,
          searchString,
          isMobile
        ).pageTitleLine2?.toUpperCase()}>
        <div className='z-10 mx-auto flex flex-col justify-center flex-wrap h-full text-brightYellow'>
          <button
            type='button'
            onClick={() => setIsSideMenuOpen(true)}
            className='mb-12 xl:mb-20'></button>
          <div className='relative'></div>
        </div>
      </Layout>
    </>
  );
};

export default GuideLines;
